._2oSbenaQCQZPXOD75Sj6nE {
  border: 1px solid #414141 !important;
}

._23VzqtDiBsdmyddgDcP2FF,
._2JJI18xT4B0iIKyuZLMpk9 {
  fill: #414141 !important;
}

.fe6xZVPr1kb0CJksW39zV {
  font-size: 12px !important;
  color: #414141 !important;
}

.MuiPaper-elevation6 {
  background-color: #efc743 !important;
}

.MuiIconButton-colorSecondary {
  padding-bottom: 6px !important;
  padding-top: 6px !important;
  color: #414141 !important;
}

.Mui-checked {
  color: #414141 !important;
}

.MuiFormControlLabel-label {
  font-size: 12px !important;
  width: 100% !important;
}

.hotspot {
  margin: 0;
}
.hotspot .button-wrap {
  display: table;
}
.pulse-button {
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  color: #ffffff;
  border: none;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  border-radius: 100%;
  vertical-align: auto;
  background: #efc743;
  cursor: pointer;
  -webkit-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
}

#circle {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  background: red;
}

.rcp-fields {
  display: none !important;
}


.none{
  display: none !important;
}

.hotspot .pulse-button-wall {
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: relative;
  display: table-cell;
  width: 40px;
  height: 40px;
  font-size: 1.3em;
  font-weight: bold;
  color: #ffffff;
  border: none;
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  border-radius: 100%;
  vertical-align: middle;
  background: #ffffffcc;
  cursor: pointer;
  -webkit-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  -moz-animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
  animation: pulse 1.5s infinite cubic-bezier(0.66, 0, 0, 1);
}
.hotspot .pulse-button:hover {
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}
.hotspot .pulse-button.deactivate {
  color: #000;
  background: #999;
  -webkit-animation: none;
  -moz-animation: none;
  animation: none;
}
@-webkit-keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 15px rgba(255, 255, 255, 0);
  }
}

.cls-1 {
  fill: #fff;
  stroke: #f4f2f3;
  stroke-miterlimit: 10;
}

*:focus {
  outline: none;
}

.MuiButton-startIcon{
  margin:0 !important
}